import React from "react";
import LoginForm from "./components/LoginForm";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css"

const App = () => {
  
 
  return (
    <div className="App">
      
      <LoginForm>
      
      </LoginForm>
      
    </div>
  );
};
export default App;
