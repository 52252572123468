import "bootstrap/dist/css/bootstrap.css"

export default function FinishForm() {
return (


  
    <div className="Finish">
        <center>  
         <img src={"https://i.imgur.com/IgpxcMq.png"} alt={"Logo"}  width={"250"} height={"75"} />  
         </center>

         <br></br>
       
  <h1>Solicitud Enviada.</h1>

     <button type="button" className="btn btn-info">
      <a href="https://ordenesservicio.appsmercadodeimportaciones.com">Regresar al inicio</a>
      </button>
       </div>

  );
}
